

.infoBox {

    line-height: 1.4em;

    h3 {
        font-size: 100%;
        font-weight: normal;
        margin: 0;
        padding: 0;

        text-transform: uppercase;
    }

    position: absolute;

    &.mobile {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 80%;
        font-size: 20px;

        padding-left: 36px;

        @media screen and (max-width: 400px) {
            font-size: 12px;
        }

        .infoBox-close {
            position: absolute;
            background-image: url('../img/Marker x.svg');

            width: 24px;
            height: 24px;

            top: 0;
            left: 0;
        }
    }

    &.desktop {
        background-color: rgba(0, 0, 0, .7);
        font-size: 16px;
        padding: 10px;
        color: white;

        .infoBox-close {
            display: none;
        }
    }

    ul {
        padding-left: 1.5em;
    }
}
