
@mixin horizontal-center {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.videoWrapper {
    position: absolute;
    left: 20%;
    width: 60%;

    @media screen and (min-width: 1000px){
        left: 10%;
        width: 80%;
    }

    .video {
        @include horizontal-center;

        width: 100%;
        height: auto;
    }

    .videoCaption {
        @include horizontal-center;

        color: white;
        font-size: 12px;
        padding-top: 1em;
        padding-bottom: 1em;

        @media screen and (min-width: 1000px) {
            font-size: 17px;
        }
    }
}
