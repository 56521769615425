.quote {
    position: absolute;

    font-size: 32px;
    font-family: $font-family-italic;

    text-shadow: 0 0 10px #000000;

    line-height: 1.1em;

    &::before {
        content: '';

        display: block;
        position: absolute;
        top: -20px;
        left: -65px;

        width: 54px;
        height: 48px;

        background-image: url('../img/Question mark.svg');
    }
}

