
.dialog {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 200;

    .closeButton {
        display: block;
        position: absolute;

        top: 0;
        right: 0;

        width: 48px;
        height: 48px;

        background-image: url('../img/Marker x.svg');

        @media screen and (min-width: 1000px) {
            width: 48px;
            height: 48px;
        }
    }

    .content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
