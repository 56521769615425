#slide_6 {
    .quote {
        left: 171px;
        top: 533px;
        width: 750px;
    }

    #toggleText_6_1 {
        right: (1024px-171px-750px+10px);
        bottom: (768px-533px+10px);
        width: 650px;

        @media screen and (min-width: 1000px) {
            width: 234px;
        }
    }
}
