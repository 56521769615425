#slide_2 {
    .quote {
        position: absolute;
        left: 159px;
        top: 104px;
        width: 514px;
    }

    #toggleText2 {
        left: 149px;
        top: 280px;
        width: 800px;

        @media screen and (min-width: 1000px) {
            width: 305px;
        }
    }

    @media screen and (min-width: 1000px) {
        #infoTextWestin{
            left: 140px;
            top: 384px;
            width: 240px;
        }

        #infoTextElbphilharmonie {
            left: 239px;
            top: 365px;
            width: 233px;
        }

        #infoTextPenthouse {
            left: 729px;
            top: 159px;
            width: 261px;
        }

        #infoTextKaispeicher {
            left: 609px;
            top: 372px;
            width: 309px;
        }
    }
}
