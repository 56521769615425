#slide_7 {

    #back_button {
        $backButtonWidth: 185px;

        display: block;

        position: absolute;
        left: 50%;
        bottom: 13%;

        width: $backButtonWidth;

        background-color: rgba(0, 0, 0, 0.5);
        padding: 8px 0;
        border-radius: 5px;

        margin-left: -($backButtonWidth/2);

        color: white;
        text-align: center;
        vertical-align: middle;

        text-decoration: none;
    }
}

.presentationMode #slide_7 #back_button {
    display: none;
}
