#slide_3 {
    #toggleText_3_1 {
        left: 140px;
        bottom: 220px;
        width: 800px;

        @media screen and (min-width: 1000px) {
            width: 218px;
        }
    }

    @media screen and (min-width: 1000px) {
        #infoBox_3_1 {
            left: 510px;
            top: 86px;
            width: 273px;
        }
    }

    .quote {
        left: 146px;
        top: 560px;
        width: 590px;
    }
}
