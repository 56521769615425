
@import 'slideshow';


$font-family-regular: 'Futura LT W04 Light', sans-serif;
$font-family-italic: 'Futura LT W04 Light Oblique', sans-serif;

@mixin marker-size($size) {
    width: $size;
    height: $size;

    margin-left: -($size/2);
    margin-top: -($size/2);
}

body {
    background-color: black;
    font-family: $font-family-regular;
}

*, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@mixin fullscreenRatio() {
    width: 100vw;
    height: 100vh;

    max-width: 177vh;
    max-height: 75vw;
}

#content_container {
    position: relative;

    @include fullscreenRatio;

    margin-left: auto;
    margin-right: auto;
}

#bentley_logo {
    display: block;
    position: absolute;

    left: 1%;
    bottom: 2%;

    width: 10%;
}

#autobild_logo {
    display: block;
    position: absolute;

    right: 1%;
    bottom: 2%;

    width: 5%;
}

.slide {
    position: relative;;

    @include fullscreenRatio;

    background-position: center center;
    background-size: cover;

    .scaling_content {
        position: relative;
        width: 1024px;
        height: 768px;

        transform-origin: top left;

        opacity: 1;
        transition: opacity 300ms;
    }

    .mask {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        opacity: 0;

        transition: opacity 300ms;

        background-color: black;
    }
}

@keyframes marker-fade-in {
    0%   { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0.8; }
}

.marker {
    position: absolute;

    @include marker-size(24px);
    @media screen and (min-width: 1000px) {
        @include marker-size(48px);
    }

    opacity: 0;

    background-image: url('../img/Marker i.svg');

    &.close {
        background-image: url('../img/Marker x.svg');
    }

    &.video {
        background-image: url('../img/Marker Video.svg');
    }

    &.sound {
        background-image: url('../img/Marker Sound.svg');
    }

    &.gallery {
        background-image: url('../img/Marker Image.svg');
    }
}

.slick-current {
    .marker {
        animation-name: marker-fade-in;
        animation-duration: 1000ms;
        animation-delay: 1500ms;
        animation-fill-mode: backwards;

        opacity: 0.8;
    }
}

#slideshow.presentationMode {
    .marker {
        opacity: 0 !important;

        &.present {
            opacity: 1 !important;
        }
    }

    .scaling_content {
        opacity: 0;

        &.present {
            opacity: 1;
        }
    }

    > .slick-dots {
        opacity: 0;
    }

    > .slick-next,
    > .slick-prev
    {
        opacity: 0;
    }

    .mask {
        opacity: 0.8 !important;
    }
}

.slide {
    color: white;

    .scaling_content {
        position: absolute;
        width: 1024px;
        height: 768px;

        transform-origin: top left;
    }
}

.headline {
    font-size: 54px;
}

.subline {
    font-size: 32px;
    line-height: 1.2em;
}

.bodytext {
    font-size: 17px;
}

@import "quote";
@import "infobox";
@import "toggleText";
@import "dialog";
@import "video";
@import "gallery";
@import "imprint";

@import "slide_1";
@import "slide_2";
@import "slide_3";
@import "slide_4";
@import "slide_5";
@import "slide_6";
@import "slide_7";
