#slide_5 {

    .quote {
        left: 102px;
        top: 123px;
        width: 895px;
    }

    #toggleText_5_1 {
        left: 92px;
        top: 245px;
        width: 800px;
    }

    @media screen and (min-width: 1000px) {
        #infoBox_5_1 {
            left: 99px;
            top: 205px;
            width: 266px;
        }
        #infoBox_5_2 {
            left: 464px;
            top: 194px;
            width: 278px;
        }
        #infoBox_5_3 {
            left: 370px;
            top: 469px;
            width: 268px;
        }
        #infoBox_5_4 {
            left: 748px;
            top: 194px;
            width: 268px;
        }
        #infoBox_5_5 {
            left: 698px;
            top: 450px;
            width: 236px;
        }
    }
}
