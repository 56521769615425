#slide_1 {
    overflow: hidden;

    .headline {
        position: absolute;
        left: 112px;
        top: 90px;
    }

    .subline {
        position: absolute;
        left: 112px;
        top: 161px;
        width: 740px;
    }

    #toggleText1 {
        left: 102px;
        top: 246px;

        width: 830px;

        @media screen and (min-width: 1000px) {
            width: 450px;
        }
    }

    @media screen and (min-width: 1000px) {
        #infoText_1_1 {
            left: 628px;
            top: 260px;
            width: 276px;
        }
    }
}
