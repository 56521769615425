#imprint_link {
    position: absolute;
    right: 9%;
    bottom: 2%;
    z-index: 200;

    text-decoration: none;
    color: white;
    font-size: 14px;
}
