#slide_4 {
    .quote {
        left: 68px;
        top: 128px;
        width: 256px;

        text-align: right;
        text-indent: 5%;

        &::before {
            left: 20px;
        }
    }

    .toggleText {
        left: 392px;
        top: 148px;
        width: 550px;

        @media screen and (min-width: 1000px) {
            width: 440px;
        }
    }

    @media screen and (min-width: 1000px) {
        #infoBox_4_1 {
            left: 720px;
            top: 102px;
            width: 257px;
        }
    }
}
