
.toggleText {
    display: none; // initial state
    position: absolute;

    padding-left: 10px;
    padding-right: 10px;

    font-size: 32px;
    line-height: 1.4em;

    @media screen and (min-width: 1000px) {
        position: absolute;
        background-color: rgba(0,0,0,.7);
        font-size: 17px;
        line-height: 1.4em;

        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.marker.toggle {
    background-image: url('../img/Marker Plus.svg');

    &.visible {
        background-image: url('../img/Marker Minus.svg');
    }
}
