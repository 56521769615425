
.galleryWrapper {
    position: absolute;
    display: block;

    width: 52%;
    left: ((100%-52%)/2);

    @media screen and (min-width: 1000px) {
        width: 68%;
        left: 16%;
    }

    .galleryBig {
        flex-grow: 1;

        img {
            width: 100%;
            height: auto;
        }

        > .slick-arrow {
            display: block;
            position: absolute;
            top: 50%;
            width: 48px;
            height: 48px;

            background-color: transparent;
            border: none;

            font-size: 0;
            line-height: 0;
            color: transparent;
        }

        > .slick-prev {
            left: -72px;
            background-image: url('../img/Slideshow prev.svg');
        }

        > .slick-next {
            right: -72px;;
            background-image: url('../img/Slideshow next.svg');
        }

        .caption {
            color: white;

            font-size: 14px;
            @media screen and (min-width: 1000px) {
                font-size: 16px;
            }
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    .gallerySmall {
        margin-left: -10px;
        margin-right: -10px;

        @media screen and (min-aspect-ratio: 1024/650) {
            display: none;
        }

        img {
            height: auto;
            margin-left: 10px;
            margin-right: 10px;

            opacity: .5;

            border: 1px solid white;

            &.slick-current {
                opacity: 1;
            }
        }
    }
}
