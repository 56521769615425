
@mixin button-size($size) {
    width: $size;
    height: $size;
    margin-top: -$size/2;

}

#slideshow {
    position: relative;
    width: 100%;
    height: 100%;

    > .slick-arrow {
        position: absolute;

        font-size: 0;
        line-height: 0;

        top: 50%;
        display: block;

        @include button-size(30px);
        @media screen and (min-width: 1000px) {
            @include button-size(48px);
        }

        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;

        z-index: 100;

        background-color: transparent;

        opacity: 1;

        transition: opacity 300ms;

        &.slick-disabled {
            opacity: 0;
        }
    }

    > .slick-prev {
        left: 1%;
        background-image: url('../img/Slideshow prev.svg');
    }

    > .slick-next {
        right: 1%;
        background-image: url('../img/Slideshow next.svg');
    }

    > .slick-dots {
        display: block;

        position: absolute;
        bottom: 2%;
        z-index: 100;

        width: 100%;

        padding: 0;
        margin: 0;

        color: white;
        list-style-type: none;

        text-align: center;

        opacity: 1;
        transition: opacity 300ms;

        > li {
            position: relative;
            display: inline-block;

            cursor: pointer;

            margin-left: 4px;
            margin-right: 4px;

            > button {
                width: 16px;
                height: 16px;

                transition: opacity 300ms;
                opacity: 0.5;

                border: none;
                background-color: black;
                border-radius: 16px;

                color: white;
                font-size: 10px;
                font-weight: bold;

                text-align: center;
            }

            &.slick-active > button {
                opacity: 1;
            }
        }
    }
}
